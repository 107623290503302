var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NavQuestion',{attrs:{"background":'bg-payment',"heading":'Payment Due: £' + _vm.totalPrice,"number":_vm.questionNumber,"questionOptions":true},scopedSlots:_vm._u([{key:"contents",fn:function(){return [_c('div',{staticClass:"app-table-wrapper fadeIn-1"},[_c('div',{staticClass:"app-table"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Item Description")]),_c('th',{staticClass:"align-center"},[_vm._v("price")])])]),_c('tbody',[(_vm.basket.will_for === 'Myself')?_c('tr',{staticClass:"app-table-row"},[_c('td',{staticClass:"item-name align-middle"},[_c('img',{attrs:{"alt":"product","src":"https://mylastwill.s3.amazonaws.com/static/img/button-basket-single.png"}}),_c('div',{staticClass:"inline-block align-middle"},[_c('h5',{staticClass:"mb15"},[_vm._v(" "+_vm._s(_vm.singleWillDescription)+" ")]),_c('div',{staticClass:"width400"},[_vm._v(" "+_vm._s(_vm.singleWillSubDesciption)+" ")])])]),_c('td',{staticClass:"align-middle"},[_c('h4',[_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm.singlePrice))])])])]):_vm._e(),(
                !_vm.basket.active_subscription && _vm.basket.will_for !== 'Myself'
              )?_c('tr',{staticClass:"app-table-row"},[_c('td',{staticClass:"item-name align-middle"},[_c('img',{attrs:{"alt":"product","src":"https://mylastwill.s3.amazonaws.com/static/img/button-basket-mirror.png"}}),_c('div',{staticClass:"inline-block align-middle"},[_c('h5',{staticClass:"mb15"},[_vm._v("2 x Last Will & Testament")]),_c('div',{staticClass:"width400"},[_vm._v(" Price includes a second document for your partner "+_vm._s(_vm.partnerName)+" (after payment is completed a code will be provided for "+_vm._s(_vm.himHer)+") ")])])]),_c('td',{staticClass:"align-middle"},[_c('h4',[_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm.jointPrice))])])])]):_vm._e(),(_vm.basket.package && !_vm.basket.active_subscription)?_c('tr',{staticClass:"app-table-row"},[_c('td',{staticClass:"item-name align-middle"},[_c('img',{attrs:{"alt":"product","src":"https://mylastwill.s3.amazonaws.com/static/img/button-basket-package.png"}}),_c('div',{staticClass:"inline-block width400 align-middle"},[_c('h5',{staticClass:"mb15"},[_vm._v(" Updates, Printing & Storage Enhancements ")]),_c('div',[_vm._v(" Ensure your Will is kept safe and up-to-date with our storage and updates package. ")]),_c('div',{staticClass:"item-benefit"},[_c('i',{staticClass:"fa fa-check c-green"}),_vm._v("Secure Will Storage ")]),_c('div',{staticClass:"item-benefit"},[_c('i',{staticClass:"fa fa-check c-green"}),_vm._v("Unlimited Updates ")]),_c('div',{staticClass:"item-benefit"},[_c('i',{staticClass:"fa fa-check c-green"}),_vm._v("Will Registration ")]),_c('div',{staticClass:"item-benefit"},[_c('i',{staticClass:"fa fa-check c-green"}),_vm._v("Printing & Binding ")])])]),_c('td',{staticClass:"align-middle"},[_c('h4',[_vm._v(" £"+_vm._s(_vm.prices.package_yearly / 100)+" "),_c('span',{staticClass:"payment-frequency"},[_vm._v("per year")])])])]):_vm._e(),(
                _vm.basket.printing &&
                !_vm.basket.package &&
                !_vm.basket.active_subscription
              )?_c('tr',{staticClass:"app-table-row"},[_c('td',{staticClass:"item-name align-middle"},[_c('img',{attrs:{"alt":"product","src":"https://mylastwill.s3.amazonaws.com/static/img/button-basket-printing.png"}}),_c('div',{staticClass:"inline-block width400 align-middle"},[_c('h5',{staticClass:"mb15"},[_vm._v("Printing")]),_c('div',[_vm._v(" Printing, binding and postage of documents ready for signing. ")])])]),_c('td',{staticClass:"align-middle"},[_c('h4',{domProps:{"innerHTML":_vm._s(_vm.printingPrice)}})])]):_vm._e(),(_vm.basket && _vm.basket.active_subscription)?_c('tr',{staticClass:"app-table-row no-border"},[_c('td',{staticClass:"item-name align-middle"},[_c('div',{staticClass:"align-right"},[_c('h5'),_c('div',[_vm._v(" "+_vm._s(_vm.subscriptionDescription)+" ")])])]),_c('td',{staticClass:"align-middle"},[_c('h5',{staticClass:"no-wrap"},[_vm._v("(-£"+_vm._s(_vm.discountUpdatePrice)+")")])])]):(
                _vm.will &&
                _vm.basket &&
                _vm.will.update &&
                !_vm.basket.active_subscription &&
                _vm.basket.single_price === 0
              )?_c('tr',{staticClass:"app-table-row no-border"},[_c('td',{staticClass:"item-name align-middle"},[_c('div',{staticClass:"align-right"},[_c('h5'),_c('div',[_vm._v(" "+_vm._s(_vm.updateDescription)+" ")])])]),_c('td',{staticClass:"align-middle"},[_c('h5',{staticClass:"no-wrap"},[_vm._v("(-£"+_vm._s(_vm.discountUpdatePrice)+")")])])]):(_vm.basket && _vm.will && _vm.basket.coupon && !_vm.will.update)?_c('tr',{staticClass:"app-table-row no-border"},[_c('td',{staticClass:"item-name align-middle"},[_c('div',{staticClass:"align-right"},[_c('h5',[_vm._v(_vm._s(_vm.couponFrom))]),_c('div',[_vm._v(" Code Applied = "+_vm._s(_vm.basket.coupon.code)+" ")])])]),_c('td',{staticClass:"align-middle"},[_c('h5',{staticClass:"no-wrap"},[_vm._v("(-£"+_vm._s(_vm.discountAmount)+")")])])]):_vm._e(),_c('tr',{staticClass:"app-table-row"},[_c('td',[(_vm.oneOffPaymentNotification)?_c('div',{staticClass:"notification"},[_vm._v(" "+_vm._s(_vm.oneOffPaymentNotification)+" ")]):_vm._e()]),_c('td')]),_c('tr',{staticClass:"app-table-row"},[_c('td',{staticClass:"item-name align-middle"},[_c('div',{staticClass:"align-right"},[_c('img',{staticClass:"go-cardless",attrs:{"alt":"Direct Debit payments by GoCardless","src":"https://mylastwill.s3.amazonaws.com/static/img/gocardless-small.png","width":"211"}}),_c('h5',[_c('strong',[_vm._v("Total")])])])]),_c('td',{staticClass:"align-middle"},[_c('h4',[_vm._v(_vm._s('£' + _vm.totalPrice))])])])])])]),_c('div',{staticClass:"bg-jaggy"})]),_c('CouponDropDown')]},proxy:true},{key:"navButtons",fn:function(){return [_c('NavQuestionButtons',{attrs:{"validated":true,"forwardText":_vm.forwardText,"backLink":'/checking',"center":false,"loading":_vm.loading,"forwardLinkAlternative":_vm.newMethod[0],"forwardTextAlternative":_vm.newMethod[1],"forwardDisabled":_vm.loading},on:{"forwardClick":_vm.chargeForWill}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }