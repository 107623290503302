<template>
  <NavQuestion
    v-bind:background="'bg-payment'"
    v-bind:heading="'Payment Due: £' + totalPrice"
    v-bind:number="questionNumber"
    v-bind:questionOptions="true"
  >
    <template v-slot:contents>
      <div class="app-table-wrapper fadeIn-1">
        <div class="app-table">
          <table>
            <thead>
              <tr>
                <th>Item Description</th>
                <th class="align-center">price</th>
              </tr>
            </thead>
            <tbody>
              <!-- single will -->
              <tr v-if="basket.will_for === 'Myself'" class="app-table-row">
                <td class="item-name align-middle">
                  <img
                    alt="product"
                    src="https://mylastwill.s3.amazonaws.com/static/img/button-basket-single.png"
                  />
                  <div class="inline-block align-middle">
                    <h5 class="mb15">
                      {{ singleWillDescription }}
                    </h5>
                    <div class="width400">
                      {{ singleWillSubDesciption }}
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <h4>
                    <span class="price">{{ singlePrice }}</span>
                  </h4>
                </td>
              </tr>
              <!-- joint will -->
              <tr
                v-if="
                  !basket.active_subscription && basket.will_for !== 'Myself'
                "
                class="app-table-row"
              >
                <td class="item-name align-middle">
                  <img
                    alt="product"
                    src="https://mylastwill.s3.amazonaws.com/static/img/button-basket-mirror.png"
                  />
                  <div class="inline-block align-middle">
                    <h5 class="mb15">2 x Last Will &amp; Testament</h5>
                    <div class="width400">
                      Price includes a second document for your partner
                      {{ partnerName }} (after payment is completed a code will
                      be provided for {{ himHer }})
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <h4>
                    <span class="price">{{ jointPrice }}</span>
                  </h4>
                </td>
              </tr>
              <!-- package -->
              <tr
                v-if="basket.package && !basket.active_subscription"
                class="app-table-row"
              >
                <td class="item-name align-middle">
                  <img
                    alt="product"
                    src="https://mylastwill.s3.amazonaws.com/static/img/button-basket-package.png"
                  />
                  <div class="inline-block width400 align-middle">
                    <h5 class="mb15">
                      Updates, Printing & Storage Enhancements
                    </h5>
                    <div>
                      Ensure your Will is kept safe and up-to-date with our
                      storage and updates package.
                    </div>
                    <div class="item-benefit">
                      <i class="fa fa-check c-green"></i>Secure Will Storage
                    </div>
                    <div class="item-benefit">
                      <i class="fa fa-check c-green"></i>Unlimited Updates
                    </div>
                    <div class="item-benefit">
                      <i class="fa fa-check c-green"></i>Will Registration
                    </div>
                    <div class="item-benefit">
                      <i class="fa fa-check c-green"></i>Printing & Binding
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <h4>
                    £{{ prices.package_yearly / 100 }}
                    <span class="payment-frequency">per year</span>
                  </h4>
                </td>
              </tr>
              <!-- printing -->
              <tr
                v-if="
                  basket.printing &&
                  !basket.package &&
                  !basket.active_subscription
                "
                class="app-table-row"
              >
                <td class="item-name align-middle">
                  <img
                    alt="product"
                    src="https://mylastwill.s3.amazonaws.com/static/img/button-basket-printing.png"
                  />
                  <div class="inline-block width400 align-middle">
                    <h5 class="mb15">Printing</h5>
                    <div>
                      Printing, binding and postage of documents ready for
                      signing.
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <h4 v-html="printingPrice"></h4>
                </td>
              </tr>
              <!-- subscription -->
              <tr
                v-if="basket && basket.active_subscription"
                class="app-table-row no-border"
              >
                <td class="item-name align-middle">
                  <div class="align-right">
                    <h5></h5>
                    <div>
                      {{ subscriptionDescription }}
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <h5 class="no-wrap">(-£{{ discountUpdatePrice }})</h5>
                </td>
              </tr>
              <!-- update -->
              <tr
                v-else-if="
                  will &&
                  basket &&
                  will.update &&
                  !basket.active_subscription &&
                  basket.single_price === 0
                "
                class="app-table-row no-border"
              >
                <td class="item-name align-middle">
                  <div class="align-right">
                    <h5></h5>
                    <div>
                      {{ updateDescription }}
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <h5 class="no-wrap">(-£{{ discountUpdatePrice }})</h5>
                </td>
              </tr>
              <!-- coupon applied -->
              <tr
                v-else-if="basket && will && basket.coupon && !will.update"
                class="app-table-row no-border"
              >
                <td class="item-name align-middle">
                  <div class="align-right">
                    <h5>{{ couponFrom }}</h5>
                    <div>
                      Code Applied =
                      {{ basket.coupon.code }}
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <h5 class="no-wrap">(-£{{ discountAmount }})</h5>
                </td>
              </tr>
              <!-- total cost -->
              <tr class="app-table-row">
                <td>
                  <div v-if="oneOffPaymentNotification" class="notification">
                    {{ oneOffPaymentNotification }}
                  </div>
                </td>
                <td></td>
              </tr>

              <tr class="app-table-row">
                <td class="item-name align-middle">
                  <div class="align-right">
                    <img
                      alt="Direct Debit payments by GoCardless"
                      class="go-cardless"
                      src="https://mylastwill.s3.amazonaws.com/static/img/gocardless-small.png"
                      width="211"
                    />
                    <h5><strong>Total</strong></h5>
                  </div>
                </td>
                <td class="align-middle">
                  <h4>{{ '£' + totalPrice }}</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bg-jaggy"></div>
      </div>
      <CouponDropDown></CouponDropDown>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="true"
        v-bind:forwardText="forwardText"
        v-bind:backLink="'/checking'"
        v-bind:center="false"
        v-bind:loading="loading"
        @forwardClick="chargeForWill"
        :forwardLinkAlternative="newMethod[0]"
        :forwardTextAlternative="newMethod[1]"
        :forwardDisabled="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import NavQuestion from '@/common/ui/NavQuestion'
import CouponDropDown from './CouponDropDown'
import { http } from '@/services'

export default {
  name: 'PayPage',
  components: {
    NavQuestionButtons,
    NavQuestion,
    CouponDropDown
  },
  mounted() {
    this.$store.commit('checkoutProgress', {
      checking: true,
      printing: true,
      createWill: true,
      package: true
    })
  },
  computed: {
    basket() {
      return this.$store.getters.checkoutBasket
    },
    totalPrice() {
      return this.basket.total_price / 100
    },
    partnerName() {
      if (this.$store.getters.personalDetails.partner_full_name)
        return this.$store.getters.personalDetails.partner_full_name.split(
          ' '
        )[0]
      return ''
    },
    himHer() {
      var gender = this.$store.getters.personalDetails.partner_gender
      if (gender === 'Male') return 'him'
      if (gender === 'Female') return 'her'
      return null
    },
    will() {
      if (
        this.$store.getters.willPreview &&
        this.$store.getters.willPreview.will_ob
      )
        return this.$store.getters.willPreview.will_ob
      else this.$store.dispatch('willPreviewFetch')
      return null
    },
    prices() {
      return this.$store.getters.prices
    },
    singleWillDescription() {
      if (this.will && this.will.update) return 'Will Update'
      if (this.basket.coupon && this.basket.coupon.partner_code)
        return 'Mirror Will'
      return 'Single Will'
    },
    singleWillSubDesciption() {
      if (this.basket.coupon && this.basket.coupon.partner_code)
        return "This document will be checked in conjunction with your partner's Will."
      return 'This is a single document for a single person.'
    },
    singlePrice() {
      if (this.will && this.will.update && this.basket.single_price === 0)
        return '£' + this.prices.update_single / 100
      if (this.basket.single_price === this.prices.update_single)
        return '£' + this.prices.update_single / 100
      return '£' + this.prices.single / 100
    },
    jointPrice() {
      return '£' + this.prices.joint / 100
    },
    couponFrom() {
      if (this.basket.coupon && this.basket.coupon.broker)
        return this.basket.coupon.broker.company_name + ' discount'
      if (this.basket.coupon && this.basket.coupon.charity)
        return this.basket.coupon.charity.name + ' discount'
      if (this.basket.coupon && this.basket.coupon.partner_code)
        return 'Partner referral'
      return ''
    },
    oneOffPaymentNotification() {
      if (this.basket.charge_now_price && !this.basket.package) {
        var string = ''
        if (
          this.basket.printing &&
          this.basket.charge_now_price === this.prices.printing
        )
          string +=
            'Printing is securely charged using a one-off payment by Direct Debit provided by GoCardless.'
        else if (
          this.basket.printing &&
          this.basket.charge_now_price !== this.prices.printing
        )
          string +=
            'Wills and printing are securely charged using a one-off payment by Direct Debit provided by GoCardless.'
        else if (!this.basket.printing)
          string +=
            'Wills are securely charged using a one-off payment by Direct Debit provided by GoCardless.'
        return string
      }
      return false
    },
    discountAmount() {
      if (this.basket.coupon && this.basket.coupon.price_discount_amount)
        return this.basket.coupon.price_discount_amount
      if (this.basket.coupon && this.basket.coupon.price_discount_percentage) {
        if (this.basket.will_for === 'Myself') {
          return (
            (this.$store.getters.prices.single *
              (this.basket.coupon.price_discount_percentage / 100)) /
            100
          )
        } else {
          return (
            (this.$store.getters.prices.joint *
              (this.basket.coupon.price_discount_percentage / 100)) /
            100
          )
        }
      }
      if (
        this.basket.coupon &&
        this.basket.coupon.price_fixed_single !== null &&
        this.basket.will_for === 'Myself'
      ) {
        if (
          this.$store.getters.prices.single ===
          this.basket.coupon.price_fixed_single
        )
          return this.basket.coupon.price_fixed_single / 100
        return (
          (this.$store.getters.prices.single -
            this.basket.coupon.price_fixed_single) /
          100
        )
      }
      if (this.basket.coupon && this.basket.coupon.price_fixed_joint) {
        if (
          this.$store.getters.prices.joint ===
          this.basket.coupon.price_fixed_joint
        )
          return this.basket.coupon.price_fixed_joint / 100
        return (
          (this.$store.getters.prices.joint -
            this.basket.coupon.price_fixed_joint) /
          100
        )
      }
      return null
    },
    forwardTo() {
      return '/pay/direct_debit'
    },
    forwardText() {
      if (this.basket.active_subscription || this.basket.total_price === 0)
        return 'Next'
      if (this.basket.account_holder_name && this.basket.account_number_ending)
        return (
          ' Pay with Existing details : ' +
          this.basket.account_holder_name +
          ' *** ' +
          this.basket.account_number_ending
        )
      return 'Pay'
    },
    questionNumber() {
      if (this.$store.getters.checkoutBasket.active_subscription) return '3'
      return '4'
    },
    printingPrice() {
      return '£' + this.prices.printing / 100
    },
    discountUpdatePrice() {
      if (this.will && this.will.update) return this.prices.update_single / 100
      if (this.basket && this.basket.active_subscription)
        return this.prices.single / 100
      return null
    },
    newMethod() {
      if (
        this.basket.total_price !== 0 &&
        this.basket.account_holder_name &&
        this.basket.account_number_ending
      )
        return ['/pay/direct_debit', 'Pay with new method']
      return [null, null]
    }
  },
  data() {
    return {
      loading: false,
      showCouponInput: false,
      code: '',
      updateDescription:
        'Updates to previously created Wills are free of charge within 30 days of the original purchase.',
      subscriptionDescription:
        'An update to a single Will included within your subscription.'
    }
  },
  methods: {
    chargeForWill() {
      this.loading = true
      http
        .post('/wills/api/charge_for_will_new')
        .then((response) => {
          if (response.data.create_new_dd)
            this.$router.push('/pay/direct_debit').catch((error) => {
              console.log(error)
            })
          else {
            this.$router.push('/advice').catch((error) => {
              console.log(error)
            })
            this.$notification.success('Payment Complete')
          }
          if (response.data.errors) this.displayErrors(response.data.errors)
          this.loading = false
        })
        .catch((error) => {
          if (error.response.data.errors && error.response.data.errors.length)
            this.displayErrors(error.response.data.errors)
          this.loading = false
          // next()
        })
    },
    displayErrors(errorsList) {
      if (Array.isArray(errorsList)) {
        errorsList.forEach((element) => {
          this.$notification.error(element)
        })
      } else {
        this.$notification.error(errorsList)
      }
    }
  }
}
</script>
<style scoped>
.input-standard-grey {
  background-color: #f9f9f9;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.notification {
  font-size: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  color: black;
  border-radius: 10px;
}
</style>
